import logo from "./logo.svg";
import "./App.css";
import ReactCompareImage from "react-compare-image";
import before from "./assets/images/before.jpg";
import after from "./assets/images/after.jpg";

function App() {
  return (
    <div className="App">
      <ReactCompareImage leftImage={after} rightImage={before} />;
    </div>
  );
}

export default App;
